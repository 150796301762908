import React from "react";
//import { Droppable } from "react-beautiful-dnd";
//import RootRef from "@material-ui/core/RootRef";
//import List from "@material-ui/core/List";
//import ListItemCustom from "./ListItemCustom";
//import Typography from "@material-ui/core/Typography";
import { Droppable } from "react-beautiful-dnd";
import List from '@mui/material/List';
import ListItemCustom from "./ListItemCustom"; // Ensure this component is updated too if it uses MUI
import Typography from '@mui/material/Typography';


const Column = ({ column, dragon }) => {

  return (
    <div
      style={{
        backgroundColor: "gray",
        margin: 10,
        padding: 20,
        color: "white"
      }}
    >
      <Typography variant={"h5"}>{column.label}</Typography>
      
     
      <Droppable droppableId={column.id}>
      {(provided) => (
        // Using provided.innerRef directly with the List component
        <List ref={provided.innerRef} {...provided.droppableProps}>
          {column.list.map((itemObject, index) => (
            <ListItemCustom
              key={itemObject.id} // Always use a unique key for list items
              index={index}
              itemObject={itemObject}
              dragon={dragon}
            />
          ))}
          {provided.placeholder}
        </List>
      )}
    </Droppable>
    </div>
  );
};

export default Column;
