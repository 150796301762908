import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Paper, TextField, Button, Typography } from '@mui/material';

const LoginContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  backgroundColor: theme.palette.background.default,
}));

const LoginPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: '400px',
  width: '100%',
  boxShadow: theme.shadows[5],
  borderRadius: theme.shape.borderRadius,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const Login = ({ onLogin }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = () => {
    const correctPassword = 'adappterpassword'; // Replace with your actual password
    if (password === correctPassword) {
      onLogin();
      setError('');
    } else {
      setError('Incorrect password. Please try again.');
    }
  };

  return (
    <LoginContainer>
      <LoginPaper>
        <Typography variant="h5" gutterBottom>
          Login
        </Typography>
        <TextField
          fullWidth
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          variant="outlined"
          margin="normal"
        />
        {error && <Typography color="error">{error}</Typography>}
        <StyledButton
          variant="contained"
          color="primary"
          onClick={handleLogin}
        >
          Login
        </StyledButton>
        <br/><br/>
      <a href='https://www.youtube.com/watch?v=w_6Rcrh0DZ0'>Video Demo</a>
      </LoginPaper>
    </LoginContainer>
    
  );
};

export default Login;
