import React from "react";
import {
  Typography,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Card,
  CardContent,
  CardActions,
  Paper,
  Divider,
  Box,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { styled } from "@mui/material/styles";
import CircleIcon from "@mui/icons-material/Circle";

// Define colors for different variants
const themeColors = {
  Unicorn: {
    background: "#f5ebb5",
    browserBar: "#f2d1a0",
    textPrimary: "#6c63ff",
    button: "#6c63ff",
    buttonHover: "#5845e8",
  },
  Pegasus: {
    background: "#e1f5fe",
    browserBar: "#b3e5fc",
    textPrimary: "#03a9f4",
    button: "#03a9f4",
    buttonHover: "#0288d1",
  },
  default: {
    background: "#f0f0f0",
    browserBar: "#dcdcdc",
    textPrimary: "#333",
    button: "#1976d2",
    buttonHover: "#115293",
  },
};

// Styled button with conditional investment theme colors
const StyledButton = styled(Button)(({ theme, variant }) => ({
  backgroundColor: themeColors[variant]?.button || theme.palette.primary.main,
  color: "#fff",
  marginTop: theme.spacing(2),
  transition: "transform 0.2s",
  fontWeight: 600,
  "&:hover": {
    backgroundColor: themeColors[variant]?.buttonHover || theme.palette.primary.dark,
    transform: "scale(1.05)",
  },
}));

// Container for the investment app look with conditional styles
const InvestmentContainer = styled(Card)(({ variant }) => ({
  maxWidth: 500,
  margin: "20px auto",
  padding: "24px",
  backgroundColor: themeColors[variant]?.background || "#f0f0f0",
  borderRadius: "12px",
}));

const BrowserFrame = styled(Box)(({ theme }) => ({
  maxWidth: 550,
  margin: "20px auto",
  background: "#FFF",
  boxShadow: theme.shadows[5],
  borderRadius: "12px",
  overflow: "hidden",
}));

const BrowserHeader = styled(Box)(({ theme, variant }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(1),
  backgroundColor: themeColors[variant]?.browserBar || theme.palette.grey[200],
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
}));

const BrowserAddressBar = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  padding: theme.spacing(0.5, 1),
  borderRadius: "8px",
  flexGrow: 1,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

const HeaderTypography = styled(Typography)(({ theme, variant }) => ({
  fontWeight: 700,
  color: themeColors[variant]?.textPrimary || theme.palette.text.primary,
}));

const SubHeaderTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: "0.875rem",
}));

// Styled component for uref
const UrefTypography = styled(Typography)(({ variant }) => ({
  fontWeight: 500,
  color: themeColors[variant]?.textPrimary || "#333",
  fontSize: "0.875rem",
  marginBottom: "8px",
}));

// Styled component for current balance
const BalanceTypography = styled(Typography)(({ variant }) => ({
  fontWeight: 600,
  color: "#999",
  fontSize: "0.75rem",
  marginTop: "8px",
}));

const WebApp = ({
  columns,
  uref,
  targetcol,
  targetuser,
  login,
  sendUser,
  handleURchange,
  chain,
  handleChainChange,
  address,
  handleAddressChange,
  tokenCount,
  handleTokenCountChange,
  sendToken,
  setLogin,
}) => {
  const variant = columns?.[targetcol]?.label || "default"; // Safely get the label to determine the style variant

  return (
    <BrowserFrame>
      <BrowserHeader variant={variant}>
        <Box display="flex" alignItems="center">
          <CircleIcon style={{ color: "#FF5F56", fontSize: "12px" }} />
          <CircleIcon style={{ color: "#FFBD2E", fontSize: "12px", marginLeft: "4px" }} />
          <CircleIcon style={{ color: "#27C93F", fontSize: "12px", marginLeft: "4px" }} />
        </Box>
        <BrowserAddressBar>
        <Typography variant="body2" noWrap>
  {columns?.[targetcol]?.label 
    ? `https://www.${columns[targetcol].label.toLowerCase()}.investments` 
    : 'about:blank'}
</Typography>
        </BrowserAddressBar>
        <Box width="20px" />
      </BrowserHeader>
      {columns?.[targetcol]?.label ? (
  <InvestmentContainer variant={variant}>
    <CardContent>
      <HeaderTypography variant="h5" gutterBottom>
        {columns?.[targetcol]?.label || "Investment"}'s Investments
      </HeaderTypography>
      <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px", borderRadius: "8px" }}>
        <SubHeaderTypography variant="body1" style={{ background: 'transparent' }}>
          Welcome back! Manage your assets and transfer tokens securely.
        </SubHeaderTypography>
      </Paper>
      {login && (
        <>
          <Divider style={{ marginBottom: "20px" }} />
          <UrefTypography variant="subtitle1">
            User Reference: {uref}
          </UrefTypography>
          <BalanceTypography variant="h6">
            <br />
            Current Balance: {targetuser?.bal || 0} Tokens
          </BalanceTypography>
          <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
          <Typography variant="h6" gutterBottom>
            Transfer Token
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="directory-label">Directory</InputLabel>
                <Select
                  labelId="directory-label"
                  value={sendUser}
                  label="Recipient"
                  onChange={handleURchange}
                >
                  {Object.values(columns).map((op) =>
                    op.list.map((item, index) => (
                      <MenuItem key={index} value={item.UserID}>
                        {op.label} | {item.UserID}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="chain-label">Blockchain</InputLabel>
                <Select
                  labelId="chain-label"
                  value={chain}
                  label="Chain"
                  onChange={handleChainChange}
                >
                  {targetuser?.sca?.map((item, index) => (
                    <MenuItem key={index} value={Object.keys(item)[0]}>
                      {Object.keys(item)[0]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Recipient Address"
                variant="outlined"
                placeholder="Enter address"
                value={address}
                onChange={handleAddressChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Amount"
                variant="outlined"
                placeholder="Enter amount"
                type="number"
                value={tokenCount}
                onChange={handleTokenCountChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
      {!login && (
        <StyledButton
          fullWidth
          startIcon={<PersonIcon />}
          onClick={() => setLogin(true)}
          variant={variant}
        >
          Login to Manage Investments
        </StyledButton>
      )}
    </CardContent>
    {login && (
      <CardActions style={{ justifyContent: "center", marginTop: "20px" }}>
        <StyledButton onClick={sendToken} variant={variant} fullWidth>
          Transfer Token
        </StyledButton>
      </CardActions>
    )}
  </InvestmentContainer>
) : null}

    </BrowserFrame>
  );
};

export default WebApp;
