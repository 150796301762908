import React, { useState , useEffect } from "react";
import { styled } from '@mui/material/styles';

//import makeStyles from "@material-ui/core/styles/makeStyles";// New imports for MUI v5
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, TextField, Button, Grid, MenuItem, FormControl, InputLabel, Select } from '@mui/material';

import Draggable from 'react-draggable';  // For making divs draggable
import WebApp from "./WebApp";

import { DragDropContext } from "react-beautiful-dnd";
import Column from "./Column";

import Login from "./Login"; // Import the Login component
//import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';


const Root = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflow: "hidden",
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
}));

const OperatorForm = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1),
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
}));

const UserDisplay = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2),
  overflow: "hidden",
  padding: theme.spacing(2),
  border: '1px solid #e0e0e0',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[4],
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.05)'
  }
}));

const TableHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
}));



async function fetchSCA(uuid,operator) {
    const url = `${operator.endpoint}/account/${uuid}/getaddresses`;
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${operator.apisecret}`
      }
    };
  
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Data fetched successfully:", data);
      return data;
    } catch (error) {
      console.error("Failed to fetch users:", error);
      return []; // Return an empty array or null to indicate the failure
    }
  }

async function fetchUsers(operator) {
  const url = `${operator.endpoint}/account/listusers/1`;
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${operator.apisecret}`
    }
  };

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log("Data fetched successfully:", data);
    return data;
  } catch (error) {
    console.error("Failed to fetch users:", error);
    return []; // Return an empty array or null to indicate the failure
  }
}
async function deployUser(operator,callback) { 
  const url = `${operator.endpoint}/account/create`;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${operator.apisecret}`
    }
  };

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log("Data fetched successfully:", data);
      callback(data);
  } catch (error) {
    console.error("Failed to fetch users:", error);
    return []; // Return an empty array or null to indicate the failure
  }
}
const App = () => {
  
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const transferChk = async(userref,operator,chain,address,amount)=>{

    const url = `${operator.endpoint}/sca/${userref}/${chain}/transfer`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${operator.apisecret}`
      },
      body: JSON.stringify({
        "arg": [
          tokenAddress,
          address,
          amount.toString()
        ]
      })
    };
  
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Data fetched successfully:", data);
      return data;
    } catch (error) {
      console.error("Failed to fetch users:", error);
      return []; // Return an empty array or null to indicate the failure
    }
  }
  const tin = async(uuid,operator,source)=>{

    const url = `${operator.endpoint}/transfer/receiving/${secrets[uuid]}`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${operator.apisecret}`
      },
      body: JSON.stringify({
        "endpoint": source.endpoint
      })
    };
  
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Data fetched successfully:", data);
      return data;
    } catch (error) {
      console.error("Failed to fetch users:", error);
      return []; // Return an empty array or null to indicate the failure
    }
  }
  const tout =async (uuid, operator) =>{
    const url = `${operator.endpoint}/account/operator`;
    const url2 = `${operator.endpoint}/transfer/${uuid}/change-operater`;
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${operator.apisecret}`
      }
    };
    const options2 = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${operator.apisecret}`
      }
    };
  
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Data fetched successfully:", data);
  
      options2.body = JSON.stringify({addresses: data.addresses});
  
      const response2 = await fetch(url2, options2);
      if (!response2.ok) {
        throw new Error(`HTTP error! Status: ${response2.status}`);
      }
      const data2 = await response2.json();
      console.log("Operator changed successfully:", data2);
      secrets[uuid]=data2.secretkey;
      setSecrets(secrets);
      return data2; // Return the result from the second API call
    } catch (error) {
      console.error("Failed to execute operations:", error);
      return null; // Return null to indicate failure
    }
  }
const initCol = async()=>{


  
  var initialColumns = {
  };
  var iniOp = [{id: 'one', endpoint:"https://one.relayer.adappter.xyz", label:"Unicorn", apisecret:"83pDWBkleSTTakpMY82s3bNzpZWSP4FkeguZykwVjZY=", list:[]},
  {id:"two", endpoint:"https://test.relayer.adappter.xyz", label:"Pegasus", apisecret:"0y1Uv1EQbdoL92UARmocUG3KhMz/VHym+uJ+fhGMu7A=", list:[]}];
for(let x of iniOp){
 var list = await fetchUsers(x);
 for(let y of list){
  // ...state[col],
//        list: [...state[col].list, { id: `${total}`, text: 'user' + total, bal: 10, sca:[{"bsc":"bsc"+base64Secret}, {"eth":"eth"+base64Secret}, {"base":"base"+base64Secret}]}]
 
  const sca = await fetchSCA(y.UserID, x);
  if (!Array.isArray(y.sca)) {
    console.error('Error: y.sca is not an array', y.sca);
    y.sca = [];  // Initialize as array if it's not already one
  }
  var balance =0;
  for (let key in sca) {
    if (sca.hasOwnProperty(key)) {  // Check if the key belongs to the object
      y.sca.push({ [key] : sca[key]});  // Push the value associated with the key, not the key itself
      if(key=='bsc'){ balance= await getBalance(sca[key]); console.log("BALANCE",sca[key], balance); }
    }
  }
  y.bal= balance;
  y.id=y.UserID;
  y.text=y.UserID
  x.list.push(y);
 }
   initialColumns[x.id]=x;
}
setColumns(initialColumns);


  }
 
  const createUser = (col) => {
    //fetch(){}
    changesubtitle(" operator adds a new customer /user ");

    const total = Object.values(columns).reduce((acc, column) => acc + column.list.length, 0) + 1;
    
    deployUser(columns[col],(addresses)=>{
      console.log(addresses);
      const sca =[]; const bsca='';
      for (let key in addresses.sca) {
        if (sca.hasOwnProperty(key)) {  // Check if the key belongs to the object
        sca.push({ [key] : sca[key]});  // Push the value associated with the key, not the key itself
         
        }
      }
    setColumns((state) => ({
                              ...state,
                              [col]: {
                                ...state[col],
                                list: [...state[col].list, { id: `${addresses.userid}`, text: `${addresses.userid}` + total, bal: 0 , sca:[addresses.sca] }] // Ensure ID is a string if others are
                              }
                            }));
                          })
  };
  const addOperator=()=>{
    changesubtitle("new operator joins the network");

    console.log(columns);
   var id= Object.keys(columns).length;
   id++;
   setColumns((state) => ({
    ...state,
    [id]: {id, endpoint, label, apisecret, list:[]}
  }));
    }

    const dragon = (id) => {
      console.log(secrets);
      changesubtitle("user access operator's service through app/ website");
     setLogin(false);
      if (targetid === id) {
        setShowUser(!showUser);  
      } else {
        setShowUser(true);  
      }
      setTargetid(id);  

      if (!showUser) {
        setShowForm(false);
      }
    
      // Log details about the target id from columns
      Object.keys(columns).forEach((colKey) => {
        columns[colKey].list.forEach((item) => {
          if (item.id === id) {
            console.log(`Found in column ${colKey}:`, item);
            setTargetcol(colKey);
            setTargetUser(item);
          }
        });
      });
      
      console.log('show contract details of ', id);
    }

  const toggleFormVisibility = () => {
     setShowUser(false);  
    setShowForm(!showForm); 
    
    // Toggle the visibility
    
  };

  const [columns, setColumns] = useState({});
  const [targetid, setTargetid] = useState(0);
  const [targetcol, setTargetcol] = useState(0);
  const [targetuser, setTargetUser] = useState({id:"0",sca:[{"bsc":"123"}, {"eth":"456"}, {"base":"789"}]});

  const [endpoint, setEndpoint] = useState("");
  const [label, setLabel] = useState("");
  const [apisecret, setApiSecret] = useState("");
  const [showForm, setShowForm] = useState(true);
  const [showUser, setShowUser] = useState(false);
  const [sendUser, setSendUser] = useState("");
  const [address, setAddress] = useState("");
  const [chain, setChain] = useState("");
  const [tokenCount, setTokenCount] = useState(0);
  const [login, setLogin] = useState(false);
  const [subtitle, setSubtitle] = useState("");
  const [showsub, setShowsub] = useState(false);
  const [secrets, setSecrets] = useState({});
  const { ethers } = require('ethers');
  const explorer = (chain) => {
    const explorers = {
      'ethereum': 'https://sepolia.etherscan.io',  // Ropsten Ethereum Testnet
      'bsc': 'https://testnet.bscscan.com',        // Binance Smart Chain Testnet
      'polygon': 'https://mumbai.polygonscan.com', // Mumbai Polygon Testnet
      'avalanche': 'https://testnet.snowtrace.io'  // Fuji Avalanche Testnet
    };

    return explorers[chain.toLowerCase()] || 'Chain not supported';
  }
  const listUsers=()=>{
    return [1,2,3]
  }
  const handleURchange = (event) => {
    const userRef = event.target.value;  // Get the selected UserID from the event
    let found = false;
  
    // Iterate over each property in columns to find the user
    Object.values(columns).forEach(group => {
      group.list.forEach(user => {
        if (user.UserID === userRef) {
          found = true;
          const userSca = user.sca.find(sca => sca.bsc);  // Find the 'bsc' property within the 'sca' array
      //    const userBal = user.bal;  // Get the balance
  
          if (userSca) {
            setChain('bsc');
            setAddress(userSca.bsc);  // Set the BSC address
            setTokenCount(targetuser.bal);   // Set the balance
          } else {
            console.error('BSC address not found for this user.');
          }
        }
      });
    });
  
    if (!found) {
      console.error('User not found.');
    }
  
    console.log(columns, userRef);
  };
  
  const handleEndpointChange = (event) => {
    setEndpoint(event.target.value);
  };
  const handleLabelChange = (event) => {
    setLabel(event.target.value);
  };
  const handleApiSecretChange = (event) => {
    setApiSecret(event.target.value);
  };
  const handleAddressChange=(event)=>{
    setAddress(event.target.value);
  }
  const handleTokenCountChange=(event)=>{
    setTokenCount(event.target.value)
  }
  const handleChainChange=(event)=>{
    setChain(event.target.value)
    console.log(5)
  }

  const sendToken = async () => {
    console.log(tokenCount, "tokens sent to /user reverse searched by address");
  
    // Convert tokenCount to the correct unit for the transaction
    const amount = ethers.parseUnits(tokenCount.toString(), 18);
    console.log(targetid, columns[targetcol], chain, address, amount);
  
    // Perform the token transfer
    const sendt = await transferChk(targetid, columns[targetcol], chain, address, amount);
  
    // Update the balance for the sending user (deduct tokens)
    let updatedColumns = { ...columns };
    let sendingUser = updatedColumns[targetcol].list.find((col) => col.id === targetid);
  
    const parsedTokenCount = parseFloat(tokenCount);  // Ensure tokenCount is a number
  
    if (sendingUser) {
      sendingUser.bal = (parseFloat(sendingUser.bal) - parsedTokenCount).toFixed(2);
      console.log(`Updated balance for sender: ${sendingUser.bal}`);
    }
  
    // Find the receiving user and update their balance (add tokens)
    let receivingResults = {};
  
    Object.keys(updatedColumns).forEach((columnKey) => {
      const column = updatedColumns[columnKey];
      column.list.forEach((user) => {
        const match = user.sca.some((scaItem) => scaItem[chain] === address);
        if (match) {
          receivingResults = {
            relayer: column.id,
            userId: user.id,
          };
        }
      });
    });
  
    if (receivingResults.relayer) {
      let receivingUser = updatedColumns[receivingResults.relayer].list.find(
        (col) => col.id === receivingResults.userId
      );
  
      if (receivingUser) {
        receivingUser.bal = (parseFloat(receivingUser.bal) + parsedTokenCount).toFixed(2);
        console.log(`Updated balance for receiver: ${receivingUser.bal}`);
      }
    }
  
    // Update the state with the new columns and user balance
    setColumns(updatedColumns);
  
    // Update the target user to trigger a re-render with the new balance
    let updatedTargetUser = updatedColumns[targetcol].list.find((col) => col.id === targetid);
    setTargetUser({ ...updatedTargetUser });
    console.log("Updated target user:", updatedTargetUser);
  };
  

const transferOut =(id,to)=>{
  console.log(id,to);
  //delete additionalStatus=copied
  //remove isCloned from ID
}

const confirmAcceptance = (id) => {
  var source={};
  var dest = {};
  console.log(id, columns);  // Debugging statement to log the id and current state of columns
  //tin(uuid,operator,sourceurl)
  // Clone the columns object to avoid direct mutation, essential for state management in React
  const updatedColumns = JSON.parse(JSON.stringify(columns));

  // Loop through each section by keys to maintain references
  Object.keys(updatedColumns).forEach(key => {
      const section = updatedColumns[key];
      
      // Iterate through the list and modify items as necessary
      section.list = section.list.filter(item => {
          if (item.UserID === id) {
              if(item.isCloned && item.additionalStatus != "copied"){ dest = section; }
              delete item.isCloned; // Remove 'isCloned' property for all items where UserID matches

              // Check if 'additionalStatus' equals 'copied' and remove such items
              if (item.additionalStatus === "copied") {
                 source = section;
                  return false;  // Return false to filter out this item
              }
          }
          return true;  // Keep all other items
      });
  });
  tin(id,dest,source)
console.log(dest,source);
  // Update state with the modified columns object
  setColumns(updatedColumns);
};    
const provider = new ethers.JsonRpcProvider("https://api.zan.top/node/v1/bsc/testnet/667a3df7293e4879bb69e5e3227409df");
// new ethers.providers.JsonRpcProvider('https://api.zan.top/node/v1/bsc/testnet/667a3df7293e4879bb69e5e3227409df');
const tokenAddress = '0x136294cDaaaCA1dF814af2605E8845FA52b8208A';
const tokenABI = ["function balanceOf(address owner) view returns (uint256)"];
const tokenContract = new ethers.Contract(tokenAddress, tokenABI, provider);

// Address whose balance you want to check


const getBalance = async(address) =>{
  // Query the balance
  const balance = await tokenContract.balanceOf(address);
  return parseFloat(ethers.formatUnits(balance, 18)).toFixed(2);
}

const onDragEnd = ({ source, destination, draggableId }) => {
  if (!destination) {
      // No valid destination, so nothing happens
      return;
  }

  // Get references to the start and end columns
  const start = columns[source.droppableId];
  const end = columns[destination.droppableId];

  // Create a new copy of the item to add to the destination

  if (start === end) {
    const itemBeingMoved = {...start.list[source.index]};
      // Movement within the same column
      console.log("same");
      const newList = Array.from(start.list);
      newList.splice(source.index, 1); // Remove the item from its original position
      newList.splice(destination.index, 0, itemBeingMoved); // Insert the item at the new position

      const newCol = { ...start, list: newList };
      setColumns(state => ({ ...state, [newCol.id]: newCol }));
  } else {
   
    const itemBeingMoved = {...start.list[source.index], isCloned: true};
      // Movement between different columns
      const newStartList = Array.from(start.list);
      
      // Optionally modify the original item's status in the start list
      // e.g., indicate it is now also in another column
      const originalItem = { ...itemBeingMoved, isCloned: true, additionalStatus: 'copied' };
      newStartList[source.index] = originalItem;

      // Insert the cloned item into the destination list
      const newEndList = Array.from(end.list);
      newEndList.splice(destination.index, 0, itemBeingMoved);

      const newStartCol = { ...start, list: newStartList };
      const newEndCol = { ...end, list: newEndList };

      // Update the state with changes to both columns
      setColumns(state => ({
          ...state,
          [newStartCol.id]: newStartCol,
          [newEndCol.id]: newEndCol
      }));
      console.log('end gives eoa',end);
      console.log('start calls change-operator',start);
      tout(draggableId,start);
      transferOut(draggableId,end);
      
     // console.log(`Migrate item ${draggableId} from: ${source.droppableId} to ${destination.droppableId}`);
      changesubtitle("User can change operation rights from one operator to another.");
  }

  return null;
};


const changesubtitle =(text,time = 3500) =>{
  setSubtitle(text)
  setTimeout(()=>{
    setSubtitle("");
  },time)
}
  const toggleUserVisibility = () => {
    setShowUser(!showUser); // Toggle the visibility
  };


  
  useEffect(() => {
    initCol();  // Call the function to initialize columns
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  useEffect(() => {
    if (isLoggedIn) {
      initCol();  // Initialize columns only after login
    }
  }, [isLoggedIn]);

  return (
    <div>
      {!isLoggedIn ? (
        <Login onLogin={handleLogin} /> // Show login component if not logged in
      ) : ( <Root>
      <div class="subtitle">
        <div class="position">
        <Typography variant="span" gutterBottom>
            {subtitle}
          </Typography>
        </div>

      </div>
        <OperatorForm> 
        <Button className={StyledButton} onClick={toggleFormVisibility} > Adappter Middleware Factory </Button>
       
        {showForm && (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Label"
            variant="outlined"
            placeholder="Label"
            value={label}
            onChange={handleLabelChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Endpoint"
            variant="outlined"
            placeholder="Endpoint"
            value={endpoint}
            onChange={handleEndpointChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="API Secret"
            variant="outlined"
            placeholder="API Secret"
            value={apisecret}
            onChange={handleApiSecretChange}
          />
        </Grid>
        <Grid item xs={12}>
        <Button className={StyledButton}
            onClick={addOperator}
          >
        
              <AddIcon />
            Add Operator
          </Button>
        </Grid>
      </Grid>) }
      {showUser && (  
      <Box className={UserDisplay}>
          <Typography variant="h6" gutterBottom >
            User Ref: {targetuser.text}
          </Typography>
          
          {targetuser.isCloned && ( <div><Button onClick={()=>confirmAcceptance(targetuser.id)} >Accept Tranfer In</Button></div>) }
         
          <Typography variant="body1" gutterBottom>
            Operator: {columns[targetcol].label}
          </Typography>
          <Typography variant="body1" gutterBottom component="div">
            SCA Contracts:
            <Table size="small" sx={{ mt: 1 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Chain</TableCell>
                  <TableCell>Address</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {targetuser.sca.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{Object.keys(item)[0]}</TableCell>
                    <TableCell><a href={explorer(Object.keys(item)[0])+"/address/"+Object.values(item)[0]} target="blank">{Object.values(item)[0]}</a></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Typography>

        </Box>)}
    </OperatorForm>
    <Draggable><div style={{"position":"fixed","z-index":"109"}}>
          <WebApp
      columns={columns}
      uref={targetuser.text}
      targetcol={targetcol}
      targetuser={targetuser}
      login={login}
      sendUser={sendUser}
      handleURchange={handleURchange}
      chain={chain}
      handleChainChange={handleChainChange}
      address={address}
      handleAddressChange={handleAddressChange}
      tokenCount={tokenCount}
      handleTokenCountChange={handleTokenCountChange}
      sendToken={sendToken}
      setLogin={setLogin}
    /></div>
          </Draggable>
    <div class="controlpanel">
      
    <DragDropContext onDragEnd={onDragEnd}>
       
      <Grid container direction={"row"} justify={"center"}>
        {Object.values(columns).map((column) => {
         
          return (
            <Grid item>
               <Button className={StyledButton} onClick={()=>createUser(column.id)}><AddIcon /> Create User</Button>
              <Column column={column} key={column.id} dragon={dragon}/>
              
            </Grid>
          );
        })}
      </Grid>
    </DragDropContext></div></Root>)}
    </div>
  );
};

export default App;
