import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import { Draggable } from 'react-beautiful-dnd';

const ListItemCustom = ({ itemObject, index, dragon }) => {
  var className = itemObject.isCloned ? 'item-cloned' : 'item-original';
  if(itemObject.additionalStatus){ className = "item-exiting" }
  const isDraggable = !itemObject.isCloned;

  return (
    <Draggable draggableId={itemObject.id} key={itemObject.id} index={index} isDragDisabled={!isDraggable}>
      {(provided, snapshot) => (
        <ListItem
          className={className}
          sx={{
            backgroundColor: 'background.paper',
            marginBottom: 1,
            boxShadow: 1,
            '&:hover': {
              backgroundColor: 'action.hover',
            },
            cursor: isDraggable ? 'pointer' : 'default'  // Cursor changes based on draggable status
          }}
          role={undefined}
          dense
          button
          ContainerComponent="li"
          ContainerProps={{
            ref: provided.innerRef,
            ...provided.draggableProps,
            ...provided.dragHandleProps
          }}
        >
          <ListItemText
            primary={itemObject.text}
            secondary={`Bal: ${itemObject.bal}`}
            sx={{
              '& .MuiListItemText-primary': {
                fontFamily: 'Quicksand, sans-serif',
              },
              '& .MuiListItemText-secondary': {
                fontFamily: 'Quicksand, sans-serif',
              }
            }}
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="delete"
              sx={{ color: 'error.main' }}
              onClick={() => dragon(itemObject.id)}
            >
              <PersonIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      )}
    </Draggable>
  );

};

export default ListItemCustom;
